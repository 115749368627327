<template>
  <div></div>
</template>

<script setup lang="ts">
import { successMessage } from '~/common/feedback';
import AuthService from '~/services/AuthService';

/* eslint-disable no-undef */
const { confirmationCode } = useRoute().query;

if (!confirmationCode) {
  navigateTo('/login');
} else {
  try {
    useLazyAsyncData('confirmEmail', () => AuthService.confirmEmail({ code: confirmationCode }));
    successMessage('Email подтвержден!');
    navigateTo('/login?confirmedEmail=1');
  } catch (error) {
    console.log(error);
  }
}
</script>
